body {
  background-image: linear-gradient(rgba(129, 57, 230, 0.5), rgba(129, 57, 230, 0.5)), url('./assets/acando_bg.png');
  background-size: cover;
  background-position: center;
}

.check {
  border-radius: 1rem;
  font-family: 'Inter';
}
.check:hover{
  outline: 2px solid white;
  transition: 0.5s;
}
.acd {
  font-family: 'Space Grotesk';
}
.dc {
  font-family: 'Inter';
}
.icons {
  margin-right: 30rem; /* Adjust margin relative to the width of the screen */
}

/* Media Query for adjusting styles on smaller screens */
@media screen and (max-width: 768px) {
  .icons {
    margin-right: 10px; /* Adjust margin for smaller screens */
  }
}
